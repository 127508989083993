/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

/* Handle fonts, manually importing .otf files. This step is required to ensure cross-browser rendering. */
@font-face {
  font-family: 'New Spirit Light Condensed';
  src: url('./assets/fonts/new_spirit_light_condensed.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Regular Condensed';
  src: url('./assets/fonts/new_spirit_regular_condensed.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Medium Condensed';
  src: url('./assets/fonts/new_spirit_medium_condensed.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Sprit Semibold Condensed';
  src: url('./assets/fonts/new_spirit_semibold_condensed.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Bold Condensed';
  src: url('./assets/fonts/new_spirit_bold_condensed.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Light';
  src: url('./assets/fonts/new_spirit_light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Regular';
  src: url('./assets/fonts/new_spirit_regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Medium';
  src: url('./assets/fonts/new_spirit_medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Sprit Semibold';
  src: url('./assets/fonts/new_spirit_semibold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Bold';
  src: url('./assets/fonts/new_spirit_bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Herman Light';
  src: url('./assets/fonts/new_herman_light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Herman Regular';
  src: url('./assets/fonts/new_herman_regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Herman Bold';
  src: url('./assets/fonts/new_herman_bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Herman Extrabold';
  src: url('./assets/fonts/new_herman_extrabold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Kansas Thin';
  src: url('./assets/fonts/new_kansas_thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Kansas Light';
  src: url('./assets/fonts/new_kansas_light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Kansas Regular';
  src: url('./assets/fonts/new_kansas_regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Kansas Medium';
  src: url('./assets/fonts/new_kansas_medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Kansas Semibold';
  src: url('./assets/fonts/new_kansas_semibold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Kansas Bold';
  src: url('./assets/fonts/new_kansas_bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Black';
  src: url('./assets/fonts/new_kansas_black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'New Spirit Heavy';
  src: url('./assets/fonts/new_kansas_heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;